<template>
  <div class="container-fluid">
    <div class="row justify-content-center py-3">
      <div class="col-12 col-sm-6 col-md-3 text-center">
        <div class="form-group select-with-date">
          <!-- <label for="rounds"></label> -->
          <select
            name="rounds"
            id="rounds"
            class="form-control"
            @change="changeRound"
            v-model="round"
          >
            <option value>{{ $t('selectOption') }}</option>
            <option
              v-for="round in rounds"
              :key="round.round"
              :value="round.round"
            >{{ $tc('round', 1) | toCapitalize }} {{ round.round }}</option>
          </select>
          <label
            v-if="dateGame"
            for="rounds"
            class="lbl-date-rounds"
          >{{ dateGame.date }} - {{ dateGame.time }}</label>
        </div>
      </div>
      <div class="col-12">
        <hr />
        <next-matchs :lastMatches="matches" :show-round="false" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import NextMatchs from "../../partials/NextMatchs";
import * as moment from "moment";

export default {
  components: {
    NextMatchs
  },
  data() {
    return {
      rounds: [],
      matches: [],
      dateGame: {},
      round: ""
    };
  },
  computed: {
    ...mapGetters(["lang", "console", "currentSeason", "divisionFront"])
  },
  watch: {
    currentSeason() {
      this.fetchMatches();
    }
  },
  mounted() {
    this.$store.dispatch("SET_MENU_PRIMARY_DIVISION", "matches");
    this.fetchRounds();
    this.fetchMatches();
  },
  methods: {
    changeRound() {
      this.$router.push({
        name: "DivisionUpcomingMatches",
        params: {
          lang: this.lang,
          console: this.console,
          divisionSlug: this.$route.params.divisionSlug
        },
        query: { round: this.round }
      });
    },
    fetchRounds() {
      const lang = this.$route.params.lang;
      const cons = this.$route.params.console;
      const slug = this.$route.params.divisionSlug;
      this.path = `${lang}/console/${cons}/division/${slug}/rounds`;
      this.$axios.get(this.path).then(response => {
        this.rounds = response.data.data;
      });
    },
    fetchMatches() {
      const lang = this.$route.params.lang;
      const cons = this.$route.params.console;
      const slug = this.$route.params.divisionSlug;
      const round = this.$route.query.round;
      const params = {
        season: this.currentSeason,
        round
      };
      this.round = round;
      this.path = `${lang}/console/${cons}/division/${slug}/upcoming-matches`;
      this.$axios.get(this.path, { params }).then(response => {
        this.matches = response.data.data;
        if (this.matches.length > 0) {
          const firstItem = this.matches[0];
          this.round = firstItem.round;
          if (firstItem.date_game) {
            this.dateGame = {
              date: moment(firstItem.date_game).format("DD-MM-YYYY"),
              time: moment(firstItem.date_game).format("h:mm")
            };
          }
        }
      });
    }
  }
};
</script>
